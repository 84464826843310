// src/utils/mathUtils.js

export function generateAdditionQuestion(complexity) {
    const num1 = Math.floor(Math.random() * complexity) + 1;
    const num2 = Math.floor(Math.random() * complexity) + 1;
    return {
      question: `${num1} + ${num2} =`,
      answer: num1 + num2,
    };
  }
  
  export function generateSubtractionQuestion(complexity) {
    let num1 = Math.floor(Math.random() * complexity) + 1;
    let num2 = Math.floor(Math.random() * complexity) + 1;
    if (num2 > num1) [num1, num2] = [num2, num1]; // Swap to avoid negative results
    return {
      question: `${num1} - ${num2} =`,
      answer: num1 - num2,
    };
  }
  
  export function generateMultiplicationQuestion(complexity) {
    const num1 = Math.floor(Math.random() * complexity) + 1;
    const num2 = Math.floor(Math.random() * complexity) + 1;
    return {
      question: `${num1} × ${num2} =`,
      answer: num1 * num2,
    };
  }
  
  export function generateDivisionQuestion(complexity) {
    const num2 = Math.floor(Math.random() * complexity) + 1; // Divisor
    const quotient = Math.floor(Math.random() * complexity) + 1; // Result is always a whole number
    const num1 = num2 * quotient; // Ensure numerator is divisible by denominator
    return {
      question: `${num1} ÷ ${num2} =`,
      answer: quotient,
    };
  }