// src/App.js
import React, { useState } from 'react';
import './styles/main.scss';
import {
  Button,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  MenuItem,
  Select,
  Typography,
  Paper,
  Box,
  TextField
} from '@mui/material';
import {
  generateAdditionQuestion,
  generateSubtractionQuestion,
  generateMultiplicationQuestion,
  generateDivisionQuestion,
} from './utils/mathUtils';

const App = () => {
  const [selectedOperations, setSelectedOperations] = useState({
    addition: false,
    subtraction: false,
    multiplication: true, // Default to multiplication
    division: false,
  });
  const [numQuestions, setNumQuestions] = useState(20);
  const [complexity, setComplexity] = useState(20); // Default complexity
  const [worksheet, setWorksheet] = useState([]);
  const [studentAnswers, setStudentAnswers] = useState([]);
  const [feedback, setFeedback] = useState([]);
  const [operationError, setOperationError] = useState(false); // Error for unselected operations

  const handleOperationChange = (event) => {
    setSelectedOperations({
      ...selectedOperations,
      [event.target.name]: event.target.checked,
    });
    setOperationError(false); // Reset the error when an operation is selected
  };

  const handleAnswerChange = (index, value) => {
    const updatedAnswers = [...studentAnswers];
    updatedAnswers[index] = value;
    setStudentAnswers(updatedAnswers);
    checkAnswer(index, value);
  };

  const generateWorksheet = () => {
    const selectedOps = Object.values(selectedOperations).filter(Boolean);

    // If no operations are selected, show an error
    if (selectedOps.length === 0) {
      setOperationError(true);
      return;
    }

    const operations = [];
    if (selectedOperations.addition) operations.push(generateAdditionQuestion);
    if (selectedOperations.subtraction) operations.push(generateSubtractionQuestion);
    if (selectedOperations.multiplication) operations.push(generateMultiplicationQuestion);
    if (selectedOperations.division) operations.push(generateDivisionQuestion);

    const newWorksheet = [];
    const initialAnswers = [];

    for (let i = 0; i < numQuestions; i++) {
      const randomOperation = operations[Math.floor(Math.random() * operations.length)];
      newWorksheet.push(randomOperation(complexity)); // Pass complexity
      initialAnswers.push(''); // Initialize answer fields
    }

    setWorksheet(newWorksheet);
    setStudentAnswers(initialAnswers);
    setFeedback([]);
  };

  const checkAnswer = (index, value) => {
    const correctAnswer = parseFloat(worksheet[index].answer);
    const studentAnswer = parseFloat(value);
    const updatedFeedback = [...feedback];
    updatedFeedback[index] = correctAnswer === studentAnswer;
    setFeedback(updatedFeedback);
  };

  const handlePrint = () => {
    window.print(); // Trigger the print dialog
  };

  return (
    <div className="app-container">
      <Typography variant="h4" gutterBottom component="div">
        Worksheet Generator
      </Typography>

      <Box className="controls">
        <Typography variant="h6" gutterBottom component="div">
          Select Operations:
        </Typography>
        <FormControl
          component="fieldset"
          className={`checkbox-group ${operationError ? 'error-highlight' : ''}`} // Highlight on error
        >
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedOperations.addition}
                  onChange={handleOperationChange}
                  name="addition"
                />
              }
              label="Addition"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedOperations.subtraction}
                  onChange={handleOperationChange}
                  name="subtraction"
                />
              }
              label="Subtraction"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedOperations.multiplication}
                  onChange={handleOperationChange}
                  name="multiplication"
                />
              }
              label="Multiplication"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedOperations.division}
                  onChange={handleOperationChange}
                  name="division"
                />
              }
              label="Division"
            />
          </FormGroup>
        </FormControl>

        <Typography variant="h6" gutterBottom component="div">
          Select Number of Questions:
        </Typography>
        <FormControl variant="outlined" fullWidth>
          <Select
            value={numQuestions}
            onChange={(e) => setNumQuestions(e.target.value)}
            className="input-field"
          >
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={40}>40</MenuItem>
            <MenuItem value={60}>60</MenuItem>
            <MenuItem value={80}>80</MenuItem>
            <MenuItem value={100}>100</MenuItem>
          </Select>
        </FormControl>

        <Typography variant="h6" gutterBottom component="div">
          Select Complexity:
        </Typography>
        <FormControl variant="outlined" fullWidth>
          <Select
            value={complexity}
            onChange={(e) => setComplexity(e.target.value)}
            className="input-field"
          >
            <MenuItem value={5}>Numbers ≤ 5</MenuItem>
            <MenuItem value={10}>Numbers ≤ 10</MenuItem>
            <MenuItem value={20}>Numbers ≤ 20</MenuItem>
            <MenuItem value={50}>Numbers ≤ 50</MenuItem>
            <MenuItem value={100}>Numbers ≤ 100</MenuItem>
          </Select>
        </FormControl>

        <Typography variant="body2" className="note" gutterBottom component="div">
          * For division, the complexity applies to the divisor and quotient, not necessarily the dividend.
        </Typography>

        <Button
          variant="contained"
          color="primary"
          onClick={generateWorksheet}
          style={{ marginTop: '20px' }}
        >
          Generate Worksheet
        </Button>

        {/* Add a button to trigger printing */}
        {/* Only show the Print button if the worksheet has been generated */}
        {worksheet.length > 0 && (
          <Button
            variant="contained"
            color="secondary"
            onClick={handlePrint}
            style={{ marginTop: '10px' }}
          >
            Print Worksheet
          </Button>
        )}
      </Box>

      {worksheet.length > 0 && (
        <Paper className="worksheet">
          <Box className="questions-grid">
            {worksheet.map((q, index) => (
              <Box
                key={index}
                className={`question-box ${
                  feedback[index] === true ? 'correct' : feedback[index] === false ? 'incorrect' : ''
                }`}
              >
                <Typography variant="body1" className="question" component="div">
                  <span className="question-index">{index + 1}. </span>{q.question}
                  <TextField
                    className="answer-input"
                    value={studentAnswers[index]}
                    onChange={(e) => handleAnswerChange(index, e.target.value)}
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      inputMode: 'numeric',  // Ensures the numeric keypad shows on mobile devices
                      pattern: '[0-9]*',     // Ensures numbers only
                      type: 'number',        // Restrict input to numbers
                      autoComplete: 'off',   // Disable autocomplete
                    }}
                    autoComplete="off"
                  />
                </Typography>
              </Box>
            ))}
          </Box>
        </Paper>
      )}
    </div>
  );
};

export default App;